.logout-button {
  margin-right: 16px;

  i {
    margin-right: 10px;
  }
}

.upload-photo {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  background-color: #20a8d8;
  border: 1px solid #20a8d8;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: 0;

  &:hover {
    color: #fff;
    background-color: #1b8eb7;
    border-color: #1985ac;
  }
}

.modal_window {
  p {
    text-align: center;
  }

  div {
    button {
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: 100%;
      margin: 10px;
      border: none;
      outline: none;

      &:first-child {
        background-color: #f86c6b;

        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(249, 130, 129, 0.5);
        }
      }

      &:last-child {
        background-color: #20a8d8;

        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 0.5);
        }
      }
    }
  }
}

.image_name {
  font-size: 14px;
  position: absolute;
}

.edit {
  display: inline-block;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #20a8d8;
  border-radius: 0.25rem;
  position: relative;
  min-width: 0;
  font-weight: 400;
  color: #20a8d8;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: #20a8d8;

    i {
      color: white;
    }
  }
}

.uploaded_image {
  width: 50%;
  display: block;
  margin-top: 20px;
  object-fit: contain;
}

.calendarContent table {
  tr {
    td {
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
    }
  }

  thead {
    tr {
      td {
        height: 30px;
      }

      &:last-of-type {
        td {
          font-weight: bold;
        }
      }

      &:first-of-type {
        td {
          font-weight: bolder;
          font-size: 16px;
          text-transform: uppercase;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &.holiday button {
          border: 1px solid #20a8d8;
        }

        button {
          cursor: pointer;
          height: 30px;
          width: 30px;
          border-radius: 50%;
          line-height: 1;
          outline: none;
          border: none;
          background-color: transparent;
          position: relative;

          &:hover {
            background-color: rgba(27, 142, 183, .2);
            transition: all linear .3s;

            .day_title {
              display: block;
            }
          }

          .day_title {
            position: absolute;
            bottom: calc(100% + 5px);
            word-wrap: normal;
            left: 0;
            right: 0;
            height: fit-content;
            padding: 5px 10px;
            background-color: white;
            border: 0.5px solid #555555;
            width: fit-content;
            border-radius: 5px;
            margin: auto;
            color: #555555;
            display: none;
          }
        }

        &[data-id="6"],
        &[data-id="0"] {
          button {
            color: #38a8d8;
            font-weight: bold;
          }
        }
      }
    }

  }
}

td.weekend {
  color: #38a8d8;

  .weekend button {
    color: #38a8d8;
  }
}

.month {
  margin-bottom: 40px;
  height: 360px;
}

.card-body {
  & > .row:first-of-type h5 {
    margin: auto 20px;
    align-items: center;
    height: min-content;
  }

  & > .row:first-of-type {
    margin-bottom: 20px;
  }

  &.data-list {
    max-height: 70vh;
    overflow-y: scroll;
  }

}

.calendarPopover {
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-upload {
  margin-bottom: 30px;
}
